import { Helpers } from '@/helpers';
import { createComponent } from '@/helpers/alpine';
import {
    CustomerLocation,
    TCustomerLocation,
} from '@/services/customer-location';
import { CustomerStore } from '@/services/customer-store';
import { GooglePlaceStore } from '@/services/gplace';
import Cookies from 'js-cookie';

const GOOGLE_STORE_COOKIE_EXPIRES = 30;
const GoogleStoreLatLngCacheCookie = Cookies.withConverter<TStoreCoordsAssoc[]>(
    {
        read: (value) => JSON.parse(value),
        write: (value) => JSON.stringify(value),
    },
).withAttributes({
    expires: GOOGLE_STORE_COOKIE_EXPIRES,
});

type TStoreItemOps = {
    id_store: string;
    id_storehouse: string;
    store_name: string;
    gplace_id?: string;
    lat: number;
    lng: number;
};

type TRelayItem = {
    address1: string;
    postcode: string;
    city: string;
};

type TStoreCoords = {
    lat: number;
    lng: number;
};

type TStoreCoordsAssoc = [string, number, number];

function getCache() {
    const cache = GoogleStoreLatLngCacheCookie.get('store-coords-cache');
    return (cache ?? []) as TStoreCoordsAssoc[];
}

function setCache(key: string, coords: TStoreCoords) {
    const payload: TStoreCoordsAssoc = [key, coords.lat, coords.lng];
    const cache = getCache().filter((cache) => cache[0] != key);
    cache.push(payload);

    GoogleStoreLatLngCacheCookie.set('store-coords-cache', cache);
}

const StoreItem = createComponent((opts: TStoreItemOps) => ({
    id_store: opts.id_store,
    id_storehouse: opts.id_storehouse,
    store_name: opts.store_name,
    gstore: undefined as GooglePlaceStore | undefined,
    lat: opts.lat ?? 0,
    lng: opts.lng ?? 0,
    _distance: -1,
    geocoder: undefined as google.maps.Geocoder | undefined,
    item: undefined as TRelayItem | undefined,

    getDistance(location: TCustomerLocation) {
        const { lat, lng } = this;

        if (lat == -1 || lng == -1) {
            return -1;
        }

        return (
            Helpers.GEO.distanceCosine(
                { lat, lng },
                {
                    lat: location.lat,
                    lng: location.lng,
                },
            ) / 1000
        );
    },

    get cacheKey() {
        return Helpers.Crypt.simpleInsecureHash(this.addressQuery);
    },

    get cache(): TStoreCoords | undefined {
        const cache = getCache().find((cache) => cache[0] == this.cacheKey);
        return cache ? { lat: cache[1], lng: cache[2] } : undefined;
    },

    get hasLocation() {
        return this.lat && this.lng;
    },

    get isCurrent() {
        return this.id_store == CustomerStore.get()?.id_store;
    },

    get hasGooglePlace() {
        return this.gstore !== undefined;
    },

    get isOpen() {
        return this.gstore?.isOpen;
    },

    get isClose() {
        return !this.gstore?.isOpen;
    },

    get distance() {
        return this._distance != -1 ? Math.round(this._distance) : false;
    },

    get orderStyle() {
        return this._distance != -1 ? Math.floor(this._distance) : '999';
    },

    get addressQuery() {
        return this.item
            ? this.item.address1 + this.item.postcode + this.item.city
            : '';
    },

    getTimingLabel(...items: string[]) {
        return items.filter(Boolean).join(' ');
    },

    onUpdatedCustomerLocation(location?: TCustomerLocation) {
        this._distance = location ? this.getDistance(location) : -1;
    },

    select() {
        const store_name_short = this.store_name
            .replace('Côté Clôture', '')
            .trim();

        CustomerStore.set({
            id_store: this.id_store,
            id_storehouse: this.id_storehouse,
            id_relay: '',
            store_name: this.store_name,
            store_name_short,
        });
    },

    async setupStore() {
        this.item = opts as unknown as TRelayItem;

        this.gstore = opts.gplace_id
            ? await GooglePlaceStore.find(opts.gplace_id)
            : undefined;

        if (!this.hasLocation) {
            if (!this.cache) {
                this.geocoder = new google.maps.Geocoder();
                this.geocoder?.geocode(
                    {
                        address: this.addressQuery,
                    },
                    (results, status) => {
                        if (
                            status == google.maps.GeocoderStatus.OK &&
                            results
                        ) {
                            this.lat = results[0].geometry.location.lat();
                            this.lng = results[0].geometry.location.lng();

                            setCache(this.cacheKey, {
                                lat: parseFloat(this.lat.toFixed(6)),
                                lng: parseFloat(this.lng.toFixed(6)),
                            });
                        } else {
                            setCache(this.cacheKey, {
                                lat: -1,
                                lng: -1,
                            });
                        }
                    },
                );
            } else {
                const { lat, lng } = this.cache;
                this.lat = lat;
                this.lng = lng;
            }
        }

        if (CustomerLocation.hasLocation) {
            this.onUpdatedCustomerLocation(CustomerLocation.get());
        }

        CustomerLocation.onInit((location) => {
            this.onUpdatedCustomerLocation(location);
        });

        CustomerLocation.onUpdate((location) => {
            this.onUpdatedCustomerLocation(location);
        });
    },
}));

export { StoreItem };
